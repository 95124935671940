import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'
import { Warpper } from '@raylink-overseas/common/components'

export const AgreementWrapper = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%),
    linear-gradient(
      90deg,
      rgba(212, 246, 255, 0.5) 0%,
      rgba(218, 217, 249, 0.5) 45.31%,
      rgba(225, 204, 243, 0.5) 100%
    );
  background-size: 100% 14.58vw;
  background-repeat: no-repeat;
  ${Media.phone`
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #fff 100%), linear-gradient(90deg, rgba(212, 246, 255, 0.50) 0%, rgba(218, 217, 249, 0.50) 45.31%, rgba(225, 204, 243, 0.50) 100%);
    background-size: 100% 4.8rem;
    background-repeat: no-repeat;
  `}
`
export const AgreementContent = styled(Warpper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 128px 0 112px;
  .link {
    color: ${color.primary};
    font-size: 16px;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
    :hover {
      text-decoration-line: none;
    }
  }
  .unordered-item {
    display: inline-block;
    padding-left: 22px;
    .dot-bold {
      font-weight: 500;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        background-color: #646a73;
        border-radius: 50%;
        position: absolute;
        left: -13px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  ${Media.phone`
    padding: 2.56rem 0.52rem 1.48rem;
    .link {
      font-size: 0.32rem;
    }
    .need-dot {
      padding-left: 0.44rem;
      .dot-bold {
        &::before {
          width: 0.08rem;
          height: 0.08rem;
          left: -0.26rem;
        }
      }
    }
  `}
`

export const AgreementList = styled.div`
  margin-top: 54px;
  align-self: start;
  .policy-item {
    padding: 48px 0 36px;
    :not(:last-of-type) {
      border-bottom: 1px solid #e6e6e6;
    }
  }
  .item-title {
    margin-bottom: 24px;
    color: ${color.textBlack};
    font-size: 32px;
    font-weight: 700;
  }
  .content-title {
    margin: 20px 0;
    color: ${color.textDark};
    font-size: 16px;
    font-weight: 500;
  }
  .text-item {
    margin-bottom: 20px;
    color: ${color.textDark};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }
  ${Media.phone`
    margin-top: 0.72rem;
    .policy-item {
      padding: 0.64rem 0 0.46rem;
    }
    .item-title {
      margin-bottom: 0.34rem;
      font-size: 0.48rem;
    }
    .text-item {
      margin-bottom: 0.3rem;
    }
  `}
`
