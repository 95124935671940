import React from 'react'

export const mainContent = [
  {
    content: [
      {
        text: [
          'These Terms of Sale (the “Terms”) govern your purchase of the paid features for the Avica software and services (the “Product”) through the Avica Online Store (the “Store”). If your purchase was through an authorized reseller or channel partner of Avica (collectively, “Authorized Resellers”), please reference the terms of sale with such Authorized Resellers as the Terms herein may not be applicable to you.',
          'The Terms set forth your rights and obligations with respect to your purchases, including important limitations and exclusions. Your placement of an order on the Store constitutes your agreement that these Terms apply to the order. Please do not place an order if you do not agree to the Terms.',
        ],
      },
    ],
  },
  {
    title: 'Payment Methods',
    content: [
      {
        text: [
          <>
            Avica accepts PayPal payment for your online purchases through the
            Store. Once your purchase is completed online, you will subsequently
            receive an email confirmation from Avica summarizing your purchase.
            Avica may use a third-party vendor to process your payments and your
            personal information will be protected in accordance with Avica’s
            Privacy Policy at{' '}
            <a
              className="link"
              href="https://www.avica.link/privacy-policy.html"
              target="_blank"
              rel="noreferrer nofollow"
            >
              https://www.avica.link/privacy-policy.html
            </a>
            . If you choose to use PayPal as the payment method for your online
            purchase, you will be redirected from Avica’s website to PayPal’s
            payment site. Once you are on PayPal’s payment site, Avica is no
            longer responsible for protecting any personal and financial
            information that you provide to PayPal. Your PayPal account and any
            activities/transactions occurred under PayPal’s payment site are
            governed by PayPal’s terms and conditions and the information
            provided to PayPal in relation hereto will be governed by PayPal’s
            Privacy Policy.
          </>,
        ],
      },
    ],
  },
  {
    title: 'Taxes and Fees',
    content: [
      {
        text: [
          'The price of the Product is net of any taxes, duties and fees. You will be responsible for any and all sales and local taxes, duties and fees on your purchases, where applicable. Where applicable, taxes and regulatory fees will be charged on the invoices issued by Avica in accordance with local laws and regulations.  Avica, at its sole discretion, will calculate the amount of taxes due.',
          'Your acceptance of these Terms constitutes your agreement to make timely payment(s) due to Avica including, where applicable, any and all state and local taxes, duties, and fees.',
          <span className="unordered-item">
            <span className="dot-bold">Tax exemptions.</span> If you are exempt
            from any taxes or fees, you will be responsible for providing Avica
            with all appropriate tax exemption certificates and/or other
            documentation satisfactory to the applicable taxing authorities to
            substantiate such exemption status. Avica reserves the right to
            review and validate tax exemption documentation.
          </span>,
          <span className="unordered-item">
            <span className="dot-bold">Payment of Taxes and Fees.</span> You
            will pay to Avica any applicable taxes and fees. You are solely
            responsible for paying any and all taxes and fees owing as a result
            of Avica’s provision of the Services to you. If you are required to
            pay any taxes and fees, you shall pay such amounts with no reduction
            or offset in amounts payable to Avica hereunder and you will pay and
            bear such additional amount, as shall be necessary to ensure that
            Avica receives the full amount of payment required as if no such
            reduction or offset were required.
          </span>,
          <span className="unordered-item">
            <span className="dot-bold">Tax determination.</span> Tax
            determination is principally based on the location where you have
            established your business, or for individuals where that individual
            permanently resides. Avica reserves the right to cross reference
            this location against other available evidence to validate whether
            your location is accurate. In the event that your location is
            inaccurate, Avica reserves the right to charge you any outstanding
            taxes and fees.
          </span>,
          'Avica may suspend or terminate  the Services on your Avica account due to any payment delinquency and you agree to reimburse Avica for all reasonable costs and expenses incurred in collecting such delinquent amounts.',
        ],
      },
    ],
  },
  {
    title: 'Auto-renewal and Billing for a Subscription-based Product',
    content: [
      {
        text: [
          'If the Product is offered to you on a subscription basis, your purchase of the Product will enable Avica to charge your account automatically for subsequent renewal periods, depending on the subscription period you previously selected, unless and until you cancel the automatic payment feature in your Avica account. Subscription fees, including the fees for any subsequent renewal period, are subject to change for each applicable product(s) at Avica’s sole discretion.',
        ],
      },
    ],
  },
  {
    title: 'Prepaid and No Refunds',
    content: [
      {
        text: [
          'The Product may be offered to you on a subscription basis or a onetime license basis pertaining to the licensing model associated with each Product. In either case, your purchase of the Product is prepaid, final and nonrefundable. For the subscription-based products, there are no refunds or credits for partially used periods and your cancellation of the Product before the expiration date will not entitle you to any refunds or credits. No refunds or credits will be granted to match any promotional offers or price drop.',
        ],
      },
    ],
  },
  {
    title: 'Cancellation and Termination',
    content: [
      {
        text: [
          <>
            For Avica subscriptions paid via www.Avica.link, You may terminate
            Your subscription by logging into your Avica account at{' '}
            <a
              className="link"
              href="https://console.avica.link/license"
              target="_blank"
              rel="noreferrer nofollow"
            >
              https://console.avica.link/license
            </a>
            , clicking on the “Subscriptions” tab under “License,” and turning
            off auto-renewal. Your subscription will no longer auto-renew, and
            Your account will not be charged automatically at the end of Your
            current subscription period. The Product You have purchased will
            remain usable until the end of the current subscription period.
          </>,
          'For Avica subscriptions procured via Avica sales personnel (not directly purchased by You at www.Avica.link), You may cancel Your subscription by providing written notice of Your intent not to renew sent no later than thirty (30) days prior to automatic renewal. We will automatically cancel your subscription from the next month after we receive your cancellation notice.',
        ],
      },
    ],
  },
  {
    title: 'Notices',
    content: [
      {
        text: [
          'Notices by Avica to You may be sent to the email address provided by You during the online registration process or otherwise by any means that Avica determines at its sole discretion as likely to come to Your attention. All notices by You to Avica regarding these Terms shall be in writing and sent by express carrier or certified mail at the address of Avica set forth herein.',
        ],
      },
    ],
  },
  {
    title: 'Other Terms and Conditions',
    content: [
      {
        text: [
          'Avica may change any products or services offered on the Store or the prices for any such products or services, at any time, with or without notice.',
          <>
            Your use of the Product is governed separately by the Terms of
            Service and/or End User License Agreement at{' '}
            <a
              className="link"
              href="https://www.avica.link/terms-and-conditions.html"
              target="_blank"
              rel="noreferrer nofollow"
            >
              https://www.avica.link/terms-and-conditions.html
            </a>{' '}
            which are usually presented for your acceptance upon your first use
            of the Product.
          </>,
          'Avica reserves the right to change the terms and conditions of the Terms of Sale at any time.',
        ],
      },
    ],
  },
  {
    content: [
      {
        text: ['Date updated: July 30, 2023'],
      },
    ],
  },
]
