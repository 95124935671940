import React from 'react'
import Agreement from './components/agreement'
import { Title } from './atoms'
import { mainContent } from './config/terms-sale-content'

const TermsOfSale = () => {
  const renderTitle = (
    <Title>
      <h1 className="title">Terms of Sale</h1>
    </Title>
  )

  return <Agreement content={mainContent} renderTitle={renderTitle} />
}

export default TermsOfSale
