import React from 'react'
import { Layout } from '@raylink-overseas/common/components'
import { AgreementWrapper, AgreementContent, AgreementList } from './atoms'

interface Props {
  content: {
    title?: string
    content: {
      subTitle?: string
      text: (string | React.JSX.Element)[]
    }[]
  }[]
  renderTitle: React.JSX.Element
}

const Agreement = (props: Props) => {
  const { content, renderTitle } = props
  return (
    <Layout pageType="policy">
      <AgreementWrapper>
        <AgreementContent>
          {renderTitle}
          <AgreementList>
            {content?.map((item, index) => (
              <div className="policy-item" key={index}>
                {item.title && <div className="item-title">{item.title}</div>}
                <div className="item-content">
                  {item.content.map((con, conIndex) => (
                    <React.Fragment key={conIndex}>
                      {con.subTitle && (
                        <div className="content-title">{con.subTitle}</div>
                      )}
                      <div className="content-text">
                        {con.text.map((text, textIndex) => (
                          <p className="text-item" key={textIndex}>
                            {text}
                          </p>
                        ))}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ))}
          </AgreementList>
        </AgreementContent>
      </AgreementWrapper>
    </Layout>
  )
}

export default Agreement
