import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'

export const Title = styled.div`
  .title {
    color: ${color.textBlack};
    text-align: center;
    font-size: 48px;
    font-weight: 800;
  }
  .sub-title {
    margin-top: 20px;
    color: ${color.textDark};
    text-align: center;
    font-size: 20px;
    font-weight: 400;
  }
  ${Media.phone`
    .title {
      font-size: 0.8rem;
    }
    .sub-title {
      margin-top: 0.28rem;
      font-size: 0.32rem;
    }
  `}
`
